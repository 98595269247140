<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">

    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col
          md="3"
          class="mb-1"
        >
          <b-card
            no-body
            class="mb-0"
          >
            <div class="m-2">
              <div class="d-flex align-items-center justify-content-center">
                <div class=" justify-content-center">
                  <div class="text-center mb-1">
                    <b-avatar
                      class="text-center"
                      rounded="sm"
                      square
                      size="10rem"
                      :src="respDataWorkFlow.detail.image"
                    />

                  </div>
                  <div class="mb-1">
                    {{ respDataWorkFlow.detail.firstName }} {{ respDataWorkFlow.detail.lastName }}
                  </div>
                  <b-badge
                    class="d-block mb-1"
                    variant="secondary"
                  >
                    {{ respDataWorkFlow.detail.statusType | firstStringUpper }}
                  </b-badge>

                </div>
              </div>
              <div class="mb-1">
                <h3 class="mb-1">
                  {{ $t('Detail') }}
                </h3>
                <hr>
                <div class="mb-1">
                  <strong><span class=""> {{ $t('Username') }} :

                  </span></strong><span>
                    {{ respDataWorkFlow.detail.userId.username }}
                  </span>
                </div>
                <div class="mb-1">
                  <strong><span class=""> {{ $t('Email') }} :

                  </span></strong><span>
                    {{ respDataWorkFlow.detail.userId.email }}
                  </span>
                </div>

                <div class="mb-1">
                  <strong><span class=""> {{ $t('Status') }} :

                  </span></strong><span>
                    {{ respDataWorkFlow.detail.statusType | firstStringUpper }}
                  </span>
                </div>
                <div class="mb-1">
                  <strong><span class=""> {{ $t('Level') }} :

                  </span></strong><span>
                    {{ selectLevel(respDataWorkFlow.detail.userId.memberLevelId) }}
                  </span>
                </div>
                <div class="mb-1">
                  <strong><span class=""> {{ $t('Tax ID') }} :

                  </span></strong><span>
                    {{ respDataWorkFlow.detail.taxID }}
                  </span>
                </div>
                <div class="mb-1">
                  <strong><span class=""> {{ $t('Contact') }} :

                  </span></strong><span>
                    {{ respDataWorkFlow.detail.tel }}

                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex mr-1"
                >

                  <span class="align-middle">{{ $t('Edit') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="d-flex ml-1"
                >
                  <span class="align-middle">{{ $t('Suspend') }}</span>

                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="9"
          class="mb-1"
        >
          <b-card
            no-body
            class="mb-0"
          >
            <div class="m-2">
              <h3 class="mb-1">
                {{ $t('Work flow') }}
              </h3>
              <hr>
              <div class="tree-container">
                <div class="tree ">
                  <ul>
                    <tree-item
                      v-for="(item, index) in treeData"
                      :key="index"
                      :item="item"
                    />
                  </ul>
                </div>
              </div>
            </div>

          </b-card>
        </b-col>
      </b-row>

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BBadge,
  BCardTitle,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/memberInvitation'
import storeModuleMemberLevel from '@/store/services/eCommerce/memberLevel'
import debounce from 'debounce'
import TreeItem from './TreeItem.vue' // Import the TreeItem component

const STORE_MODULE_NAME = 'memberInvitation'
const STORE_MODULE_NAME_MEMBER_LEVEL = 'memberLevel'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BCardTitle,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BBadge,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    TreeItem,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      // treeData: [
      //   {
      //     id: 1,
      //     label: '1',
      //     children: [
      //       {
      //         id: 2,
      //         label: '2',
      //         children: [
      //           { id: '2.1', label: '2.1', children: [] },
      //           { id: '2.2', label: '2.2', children: [] },
      //         ],
      //       },
      //       {
      //         id: 3,
      //         label: '3',
      //         children: [
      //           {
      //             id: '3.1',
      //             label: '3.1',
      //             children: [
      //               { id: '3.1.1', label: '3.1.1', children: [] },
      //               { id: '3.1.2', label: '3.1.2', children: [] },
      //             ],
      //           },
      //           { id: '3.2', label: '3.2', children: [] },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      perPageOptions,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    respDataWorkFlow() {
      return store.state[STORE_MODULE_NAME].respDataWorkFlow != null
        ? store.state[STORE_MODULE_NAME].respDataWorkFlow
        : {
          detail: {
            statusType: '',
            userId: {
              username: '',
              email: '',
            },
          },

        }
    },
    treeData() {
      return store.state[STORE_MODULE_NAME].respDataWorkFlow != null
        ? store.state[STORE_MODULE_NAME].respDataWorkFlow.treeData
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    respDataMemberLevel() {
      return store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData != null
        ? store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData.data.map(e => ({
          key: e._id, label: `${this.showFromCurrentLanguage(e.name)}`, sortable: false,
        }))
        : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL)) store.registerModule(STORE_MODULE_NAME_MEMBER_LEVEL, storeModuleMemberLevel)
    this.getMemberLevel()
    this.get()

    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL)) store.unregisterModule(STORE_MODULE_NAME_MEMBER_LEVEL)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_MEMBER_LEVEL }
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    selectLevel(id) {
      if (id !== null) {
        const index = this.respDataMemberLevel.findIndex(e => e.key === id)
        if (index > -1) {
          return this.respDataMemberLevel[index].label
        }
        return this.$t('No matching records found')
      }
      return this.$t("Didn't choose")
    },
    getMemberLevel() {
      const obj = {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_MEMBER_LEVEL}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    get() {
      const obj = {
        id: this.$route.params.id,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/workFlow`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
.tree-container {
  overflow: auto; /* แสดง Scrollbar ในแนวตั้งเมื่อรายการมีความสูงเกิน */
  padding-bottom: 10px;
}
 .tree{
    width: 6000px;
}
</style>
