<template>
  <li>
    <a href="#">
      <div class="justify-content-center">
        <div class="text-center mb-1">
          <b-avatar
            v-if="item.image"
            class="text-center"
            rounded="sm"
            square
            size="6rem"
            :src="item.image"
          />
          <b-avatar
            v-else
            class="text-center"
            rounded="sm"
            square
            size="6rem"
            :src="require('@/assets/images/blank/no_image.png')"
          />

        </div>
        <b-badge
          class="d-block mb-1"
          variant="secondary"
        >
          {{ selectLevel(item.memberLevelId) }}
        </b-badge>
        <div class="mb-1">
          {{ item.label }}
        </div>
        <b-badge
          class="d-block "
          variant="secondary"
        >
          {{ item.inviteCode }}
        </b-badge>
      </div>
    </a>
    <!-- ตรวจสอบว่ามี children หรือไม่ -->
    <ul v-if="item.children && item.children.length">
      <tree-item
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
      />
    </ul>
  </li>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BBadge,
  BCardTitle,

} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'TreeItem', // ตั้งชื่อคอมโพเนนต์ให้ถูกต้อง
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BCardTitle,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    respDataMemberLevel() {
      return store.state.memberLevel.respData != null
        ? store.state.memberLevel.respData.data.map(e => ({
          key: e._id, label: `${this.showFromCurrentLanguage(e.name)}`, sortable: false,
        }))
        : []
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    selectLevel(id) {
      if (id !== null) {
        const index = this.respDataMemberLevel.findIndex(e => e.key === id)
        if (index > -1) {
          return this.respDataMemberLevel[index].label
        }
        return this.$t('No matching records found')
      }
      return this.$t("Didn't choose")
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}

.tree ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover+ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
.tree li a:hover+ul li::after,
.tree li a:hover+ul li::before,
.tree li a:hover+ul::before,
.tree li a:hover+ul ul::before {
  border-color: #94a0b4;
}
</style>
